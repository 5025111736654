import classes from './SyncTextBadge.module.css';

import { Text, type TextProps, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';

import { ClientOnly } from '~/components/ClientOnly';

interface SyncTextBadgeProps extends TextProps {
  syncedAt?: Date;
}

export function SyncTextBadge({ syncedAt, ...props }: SyncTextBadgeProps) {
  return (
    <ClientOnly
      fallback={
      <Text className={classes.text} fw={500} {...props}>
          Last synced <strong>N/A</strong>
        </Text>}>


      {() => {
        const syncDay = syncedAt && dayjs(syncedAt);

        return (
          <Tooltip label={syncDay?.format('LLL')} hidden={!syncDay}>
            <Text className={classes.text} fw={500} {...props}>
              Last synced <strong>{syncDay?.fromNow() ?? 'N/A'}</strong>
            </Text>
          </Tooltip>);

      }}
    </ClientOnly>);

}