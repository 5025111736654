import {Badge, type BadgeProps, Tooltip} from '@mantine/core';
import dayjs from 'dayjs';

import {ClientOnly} from '~/components/ClientOnly';

interface SyncBadgeProps extends BadgeProps {
  syncedAt?: Date;
}

export function SyncBadge({syncedAt, ...props}: SyncBadgeProps) {
  return (
    <ClientOnly
      fallback={
        <Badge variant="light" size="xs" color="gray" {...props}>
          Latest Sync
        </Badge>
      }
    >
      {() => {
        const syncDay = syncedAt && dayjs(syncedAt);

        return (
          <Tooltip label={syncDay?.format('LLL')} hidden={!syncDay}>
            <Badge variant="light" size="xs" color="gray" {...props}>
              Latest Sync
            </Badge>
          </Tooltip>
        );
      }}
    </ClientOnly>
  );
}
